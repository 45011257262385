import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";

import WorkContent from "components/workContent";
import TextBlock from "components/workContent/textBlock";
import Share from "components/workContent/share";
import Arrow from "components/icons/Arrow";
import { black } from "theme/colors";

const WorkPage = (props) => {
  //PROPS
  const {
    dimensions,
    data: {
      page: {
        talentsOfWorks,
        work_info: { title, content, client, longDescription }
      }
    },
    location
  } = props;
  //PROPS

  //STATES
  const [videoID, setVideoID] = useState(null);
  const [hasBack, setBack] = useState(true);
  const [isFrom, setIsFrom] = useState(true);
  //STATES

  //USEEFFECT
  useEffect(() => {
    if (window.history.length < 3) {
      setBack(false);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.fromWork) {
      setIsFrom(false);
    }
    return () => {
      setIsFrom(true);
    };
  });
  //USEEFFECT

  return (
    <>
      {hasBack && (
        <div className="return-section">
          <button onClick={() => window.history.go(-1)}>
            <Arrow color={black.default} />
          </button>
        </div>
      )}
      <main className="main main--pad container container--small  container--center">
        <WorkContent
          rows={content}
          dimensions={dimensions}
          videoID={videoID}
          setVideoID={setVideoID}
        />
        <div className="project-info">
          {client && <h4>{client}</h4>}
          {title && <h1 className="h1-underline">{title}</h1>}
          {isFrom && (
            <h3>
              {talentsOfWorks.nodes.map((tal, index) => {
                if (talentsOfWorks.nodes.length === 1) {
                  return tal.name;
                } else if (talentsOfWorks.nodes.length >= 2 && index >= 1) {
                  return " & " + tal.name;
                }
                return tal.name;
              })}
            </h3>
          )}
          {!!longDescription && <TextBlock text={longDescription} />}
          <Share uri={location.href}></Share>
        </div>
      </main>
    </>
  );
};

export const query = graphql`
  query workPage($id: String) {
    page: wpPost(id: { eq: $id }) {
      title
      id
      uri
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      talentsOfWorks {
        nodes {
          name
        }
      }
      work_info {
        title
        client
        longDescription
        content {
          ... on WpPost_WorkInfo_Content_Images {
            images {
              image {
                altText
                mediaDetails {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                    fluid(quality: 95) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_WorkInfo_Content_Video {
            vimeo
            previewVideo {
              mediaItemUrl
            }
          }
          ... on WpPost_WorkInfo_Content_TextBlock {
            text
            title
          }
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default WorkPage;
